import React from 'react';

function ContactCard({ block }) {
  const { data } = block;
  return (
    <div className="sn-block sn-block-contact-card">
      <div
        className="contact-card-content"
        style={{ backgroundColor: data.backgroundColor, color: data.isTextWhite ? '#FFF' : '#000' }}
      >
        {data.contactImageUrl && (
          <img className="contact-card-image" src={data.contactImageUrl} alt={data.contactName} />
        )}
        <div className="contact-card-name">{data.contactName}</div>
        <div className="contact-card-title">{data.contactTitle}</div>
        <div className="contact-card-buttons">
          {data.contactPhone && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`tel:${data.contactPhone}`}
              className="contact-card-button"
              style={{ color: data.isTextWhite ? '#FFF' : '#000' }}
            >
              Call
            </a>
          )}
          {data.contactText && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`sms:${data.contactText}`}
              className="contact-card-button"
              style={{ color: data.isTextWhite ? '#FFF' : '#000' }}
            >
              Text
            </a>
          )}
          {data.contactEmail && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`mailto:${data.contactEmail}`}
              className="contact-card-button"
              style={{ color: data.isTextWhite ? '#FFF' : '#000' }}
            >
              Email
            </a>
          )}
          {data.contactAddress && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${data.contactAddress}`}
              className="contact-card-button"
              style={{ color: data.isTextWhite ? '#FFF' : '#000' }}
            >
              Directions
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactCard;
