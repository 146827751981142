import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import Button from './Button';
import SpokenoteLogo from '../images/logo.svg';
import IconMyNotes from '../images/icon-spokenote.svg';
import IconShop from '../images/icon-shop.svg';
import IconSettings from '../images/icon-settings.svg';
import { useAuth } from '../hooks/useAuth';
import { getUser } from '../libs/api';

const DONT_NAVIGATE = false;
const USER_UPDATING_PATHS = ['/account-settings', '/'];

function Navigation() {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(false);
  const { currentUser, login, logout } = useAuth();

  const closeWrapper = (fn) => () => {
    setExpanded(false);
    if (fn) fn();
  };

  const refreshUserData = React.useCallback(async () => {
    if (!currentUser || USER_UPDATING_PATHS.includes(location.pathname)) return;
    const response = await getUser();
    if (response.ok) {
      login(response.data, DONT_NAVIGATE);
    } else {
      toast.error('Something went wrong while getting your user data. Please refresh or try again later.');
    }
  }, []);

  React.useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <>
      <div
        id="navigation-overlay"
        role="presentation"
        className={expanded ? 'open' : 'close'}
        onClick={() => setExpanded(!expanded)}
      />
      <div className={`header ${expanded ? 'open' : 'close'}`}>
        <div className="header-container">
          <Button
            buttonId="nav-toggle"
            isLoading={false}
            onClick={() => setExpanded(!expanded)}
            ariaLabel="Toggle navigation"
          >
            <div id="nav-toggle-icon" aria-hidden="true">
              <span />
              <span />
              <span />
            </div>
          </Button>
          <Link to="/" id="spokenote-logo-link" aria-label="Go to Spokenote app homepage">
            <SpokenoteLogo />
          </Link>
          <div />
        </div>
      </div>
      <div className={`navigation-box ${expanded ? 'open' : 'close'}`}>
        <nav>
          <ul className="main-nav">
            {currentUser && (
              <>
                <li>
                  <Link to="/" onClick={closeWrapper()}>
                    <IconMyNotes />
                    <div>My Pages</div>
                  </Link>
                </li>
                <li>
                  <a href="https://shop.spokenote.com/" target="_blank" rel="noreferrer" onClick={closeWrapper()}>
                    <IconShop />
                    <div>Shop</div>
                  </a>
                </li>
                <li>
                  <Link to="/account-settings" onClick={closeWrapper()}>
                    <IconSettings />
                    <div>Settings</div>
                  </Link>
                </li>
              </>
            )}
          </ul>
          <ul className="auth-nav">
            {currentUser ? (
              <li>
                <Link to="/login" onClick={closeWrapper(logout)}>
                  <div>Log Out</div>
                </Link>
              </li>
            ) : (
              <li>
                <Link to="/login" onClick={closeWrapper()}>
                  <div>Login</div>
                </Link>
              </li>
            )}
            <li>
              <Link to="/help" onClick={closeWrapper()}>
                <div>Help</div>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <Outlet />
    </>
  );
}

export default Navigation;
