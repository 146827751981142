import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { EventType } from '@repo/constants';
import { getUA } from 'react-device-detect';

import { useAuth } from '../../hooks/useAuth';
import { createEvent } from '../../libs/api';
import SpokenoteVideo from './SpokenoteVideo';
import Button from './Button';
import Image from './Image';
import Text from './Text';
import LinkList from './LinkList';
import ContactCard from './ContactCard';
import VCard from './VCard';
import SocialLinks from './SocialLinks';

function Note({ page, note }) {
  const [searchParams] = useSearchParams();
  const { currentUser } = useAuth();

  const trackPlayEvent = React.useCallback((block) => {
    if ((!currentUser
      || currentUser.id !== note.creatorId)
      && searchParams.get('preview') === null
      && !page.noTracking) {
      createEvent({
        eventType: EventType.VIDEO_PLAYED,
        resourceType: 'pages',
        resourceId: page.id,
        userId: currentUser?.id || null,
        metadata: {
          noteId: note.id,
          mediaId: block.data.mediaId,
          userAgent: getUA,
        },
      });
    }
  }, [note]);

  const trackClickEvent = React.useCallback((block) => {
    if ((!currentUser || currentUser.id !== note.creatorId)
      && searchParams.get('preview') === null
      && !page.noTracking) {
      createEvent({
        eventType: EventType.CTA_CLICKED,
        resourceType: 'pages',
        resourceId: page.id,
        userId: currentUser?.id || null,
        metadata: {
          noteId: note.id,
          text: block.data.text,
          url: block.data.url,
          userAgent: getUA,
        },
      });
    }
  }, [note]);

  const buildBlock = React.useCallback((block, index) => {
    switch (block.type) {
      case 'spokenote-video':
        return (
          <SpokenoteVideo
            key={`${block.type}-${index}`}
            block={block}
            note={note}
            onPlayCallback={() => trackPlayEvent(block)}
          />
        );
      case 'button':
        return (
          <Button
            key={`${block.type}-${index}`}
            block={block}
            page={page}
            onClickCallback={trackClickEvent}
          />
        );
      case 'image':
        return <Image key={`${block.type}-${index}`} block={block} />;
      case 'text':
        return <Text key={`${block.type}-${index}`} block={block} />;
      case 'link-list':
        return <LinkList key={`${block.type}-${index}`} block={block} />;
      case 'contact-card':
        return <ContactCard key={`${block.type}-${index}`} block={block} />;
      case 'vcard':
        return <VCard key={`${block.type}-${index}`} block={block} />;
      case 'social-links':
        return <SocialLinks key={`${block.type}-${index}`} block={block} />;
      default:
        return null;
    }
  }, [note]);

  if (!note) {
    return null;
  }

  return (
    <div className={`note-${note.id}`}>
      {note.denormalized.blocks.map(buildBlock)}
    </div>
  );
}

export default Note;
