import React, { useCallback } from 'react';

import TwitterIcon from '../../images/blocks/social-twitter.svg';
import FacebookIcon from '../../images/blocks/social-facebook.svg';
import InstagramIcon from '../../images/blocks/social-instagram.svg';
import LinkedinIcon from '../../images/blocks/social-linkedin.svg';
import YoutubeIcon from '../../images/blocks/social-youtube.svg';
import TelegramIcon from '../../images/blocks/social-telegram.svg';
import TiktokIcon from '../../images/blocks/social-tiktok.svg';
import WhatsappIcon from '../../images/blocks/social-whatsapp.svg';

function SocialLinks({ block }) {
  const { data } = block;

  const getIcon = useCallback((name) => {
    switch (name) {
      case 'twitter':
        return <TwitterIcon alt={name} />;
      case 'facebook':
        return <FacebookIcon alt={name} />;
      case 'instagram':
        return <InstagramIcon alt={name} />;
      case 'linkedin':
        return <LinkedinIcon alt={name} />;
      case 'youtube':
        return <YoutubeIcon alt={name} />;
      case 'telegram':
        return <TelegramIcon alt={name} />;
      case 'tiktok':
        return <TiktokIcon alt={name} />;
      case 'whatsapp':
        return <WhatsappIcon alt={name} />;
      default:
        return null;
    }
  }, []);

  return (
    <div className="sn-block sn-block-social-bar">
      <div className="social-buttons">
        {data.socials?.map((social, index) => (
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={`social-${social.name}-${index}`}
            target="_blank"
            rel="noreferrer"
            href={social.linkUrl}
            className={`social-button social-button-${social.name}`}
          >
            {getIcon(social.name)}
          </a>
        ))}
      </div>
    </div>
  );
}

export default SocialLinks;
