"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageStatus = void 0;
exports.PageStatus = {
    UNASSIGNED: 'Unassigned',
    UNVIEWED: 'Unviewed',
    VIEWED: 'Viewed',
    CLAIMED: 'Claimed',
    UNDER_REVIEW: 'Under Review',
    DELETED: 'Deleted',
    ERRORED: 'Errored',
    LOCKED: 'Locked',
    ERROR_NOT_FOUND: 'Error Not Found',
};
exports.default = exports.PageStatus;
