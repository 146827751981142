import React from 'react';

const UTM_SOURCES = {
  production: 'spokenote',
  staging: 'spokenote-stg',
  ci: 'spokenote-ci',
};

function Button({ block, page, onClickCallback }) {
  const [url, setUrl] = React.useState(block.data.url);

  const onCTAClick = React.useCallback(() => {
    onClickCallback(block);
  }, [block]);

  React.useEffect(() => {
    if (block.data.url && block.data.url.startsWith('http')) {
      const fullUrl = new URL(block.data.url);
      const query = new URLSearchParams(fullUrl.search);
      query.set('snclid', page.id);
      query.set('utm_source', UTM_SOURCES[process.env.REACT_APP_ENV] || 'spokenote');
      fullUrl.search = query.toString();
      setUrl(fullUrl.toString());
    }
  }, [block]);

  return (
    <div className="sn-block sn-block-cta">
      <a
        href={url}
        onClick={onCTAClick}
        className="btn"
        rel="noreferrer"
        target={block.data.allowInlineLinking ? '_self' : '_blank'}
      >
        {block.data.text}
      </a>
    </div>
  );
}

export default Button;
