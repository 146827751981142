import React, { useCallback } from 'react';
import VCardCreator from 'vcard-creator';

function VCard({ block }) {
  const onButtonClick = useCallback(() => {
    const { data } = block;
    const vCard = new VCardCreator();
    vCard.addName(data.lastName, data.firstName, data.additionalName, data.namePrefix, data.nameSuffix);
    if (data.phone1) vCard.addPhoneNumber(data.phone1, data.phone1Type);
    if (data.phone2) vCard.addPhoneNumber(data.phone2, data.phone2Type);
    if (data.email) vCard.addEmail(data.email);
    if (data.address) vCard.addAddress(data.address);
    if (data.company) vCard.addCompany(data.company);
    if (data.jobTitle) vCard.addJobtitle(data.jobTitle);
    if (data.url) vCard.addURL(data.url);
    if (data.photoBase64) vCard.addPhoto(data.photoBase64, data.photoMimeType);

    const vCardString = vCard.toString();
    const blob = new Blob([vCardString], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `Contact${data.firstName}${data.lastName}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [block.data]);

  return (
    <div className="sn-block sn-block-vcard">
      <button type="button" onClick={onButtonClick} style={{ background: block?.data?.buttonColor }}>Download VCard</button>
    </div>
  );
}

export default VCard;
