import React, { createContext, useContext, useMemo } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import useLocalStorage from './useLocalStorage';
import api from '../libs/api';
import ErrorBoundary from '../components/ErrorBoundary';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useLocalStorage('auth');
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = React.useCallback(async (data, shouldNavigate = true) => {
    setAuth({ ...auth, ...data });
    if (data.token) api.setHeader('Authorization', `Bearer ${data.token}`);
    if (shouldNavigate) navigate('/');
  }, [auth]);

  // call this function to sign out logged in user
  const logout = () => {
    setAuth(null);
    api.setHeader('Authorization', null);
    navigate('/login');
  };

  const value = useMemo(() => ({
    currentUser: auth?.user,
    token: auth?.token,
    login,
    logout,
  }), [auth]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function AuthLayout() {
  const outlet = useOutlet();

  return (
    <ErrorBoundary><AuthProvider>{outlet}</AuthProvider></ErrorBoundary>
  );
}

export const useAuth = () => useContext(AuthContext);
