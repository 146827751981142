import { useState } from 'react';

const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      }
      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    if (newValue === null) {
      window.localStorage.removeItem(keyName);
    } else {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    }

    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
